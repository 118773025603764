import { Highlight, Heading, Button } from "@chakra-ui/react";
import { UnlockIcon } from "@chakra-ui/icons";
import "./Home.css";

const Home = () => {
  return (
    <>
      <div className="Home">
        <Heading lineHeight="tall">
          Welcome to the best <br />
          <Highlight
            query="Sistema Dok's"
            styles={{ px: "2", py: "1", rounded: "full", bg: "blue.100" }}
          >
            Welcome at Sistema Dok's
          </Highlight>
        </Heading>
        <div>
          <a href="https://www.sistemadoks.com.br/Doks/entrar.xhtml">
            <Button
              colorScheme="teal"
              size="lg"
              leftIcon={<UnlockIcon />}
              variant="solid"
            >
              Acessar
            </Button>
          </a>
        </div>
        <div style={{ width: "100%", marginTop: "1em" }}>
          <iframe
            width="100%"
            height="330"
            src="https://www.youtube.com/embed/f3QoQBUrJ7A"
            title="Demonstração Sistema Dok's"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default Home;
