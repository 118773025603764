import Home from "./Pages/Home";

import Logo from "./assets/img/logoDoks.svg";
import Boy from "./assets/img/fly.png";
import { Box, Heading } from "@chakra-ui/react";

function App() {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
          w="100%"
          h="200px"
          bgGradient="linear(to-r, green.400, blue.100)"
        >
          <img
            src={Logo}
            style={{
              width: "250px",
              height: "250px",
            }}
            alt="logo"
          />
        </Box>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <img src={Boy} alt="menino-voando" style={{ width: "100%" }} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          alignItems: "center",
          flexDirection: "row",
          marginTop: "1em",
        }}
      >
        <Home />
      </div>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        w="100%"
        h="200px"
        bgGradient="linear(to-r, green.900, pink.100)"
      >
        <Heading style={{ color: "#FFFF" }} as="h1" size="5xl">
          “O sucesso não é a chave para a felicidade. A felicidade é a chave
          para o sucesso.” (Albert Schweitzer)
        </Heading>
      </Box>
    </>
  );
}

export default App;
